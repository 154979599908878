import React from "react"
import { css } from "@emotion/react"
import SEO from "../components/seo"
import Navbar from "../components/Navbar"
import Layout from "../components/Layout"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { Link } from "gatsby"

const About = () => {
  return (
    <Layout>
      <SEO title="About" />
      <div
        css={css`
          p {
            font-size: 18px;
            line-height: 1.3;
          }
          a {
            color: #0178bd;
          }
        `}
      >
        <div
          css={css`
            text-align: center;
            margin-bottom: 50px;
            margin-top: 30px;
          `}
        >
          <h1
            css={css`
              margin-bottom: 0;
            `}
          >
            About
          </h1>
        </div>

        <div
          css={css`
            max-width: 800px;
            margin: 0 auto;
            padding: 0 20px;
          `}
        >
          <h2>What is Blog Surf?</h2>
          <p>
            Written content on the internet is trapped behind walled gardens and
            algorithmic feeds. The web has a wealth of knowledge, but we don't
            even have a way to tell what's out there anymore.
          </p>
          <blockquote
            css={css`
              color: #828282;
            `}
            cite="https://dkb.io/post/organize-the-world-information"
          >
            We wanted to find all the blogs that talked about the American
            Revolution. Google told us they had 148,000,000 results, but only
            showed us 410. Half of those had nothing to do with the American
            Revolution, some were from the same website, and not all of them
            were even blogs. What is going on here? (
            <a href="https://dkb.io/post/organize-the-world-information">
              dkb.io/post/organize-the-world-information
            </a>
            )
          </blockquote>
          <p>
            This is an attempt to organize all of the personal blogs on the
            internet, and make them accessible and useful.
          </p>
          <h2>What kinds of blogs are on here?</h2>
          <p>
            Only personal blogs are indexed. Corporations, large publications,
            and SEO spammers are already featured prominently in algorithmic
            feeds and search results, so there is curently no need to index
            them.
          </p>
          <h2>History Of Organizing Blogs</h2>
          <p>
            This is far from the first attempt to organize blogs on the
            internet. This is what the directories and portals did in the early
            days of the web.{" "}
            <a
              href="https://yahoo.tumblr.com/post/98474044364/progress-report-continued-product-focus"
              target="_blank"
            >
              One
            </a>{" "}
            by{" "}
            <a
              href="https://searchengineland.com/rip-dmoz-open-directory-project-closing-270291"
              target="_blank"
            >
              one
            </a>{" "}
            the directories all vanished and got replaced by Google, Facebook,
            Twitter, etc. Does the death of the directories prove that this kind
            of project is destined to fail?
          </p>
          <p>
            Beyond the classic directories, there was{" "}
            <a href="https://en.wikipedia.org/wiki/Technorati" target="_blank">
              Technorati
            </a>
            , a search engine for blogs and a blog directory. It seemed to be
            going well,{" "}
            <a
              href="https://searchengineland.com/rip-technorati-blog-search-rankings-popular-blog-tools-sunset-195186"
              target="_blank"
            >
              until it wasn't.
            </a>{" "}
            There was{" "}
            <a href="https://millionshort.com/about" target="_blank">
              Million Short
            </a>{" "}
            who wanted to make the less popular sites on the web more accessible
            by removing the top million sites from search engine results. It
            sounded like an interesting an idea, and{" "}
            <a
              href="https://news.ycombinator.com/item?id=16080610"
              target="_blank"
            >
              some people
            </a>{" "}
            seemed to like it and find it useful. And then they did a{" "}
            <a
              href="https://www.forbes.com/sites/julianmitchell/2017/12/31/this-search-engine-startup-helps-you-find-what-google-is-missing/?sh=47c692307fb4"
              target="_blank"
            >
              Forbes article
            </a>{" "}
            to talk about how they were disrupting google, right before
            vanishing with no explanation.
          </p>
          <p>
            These days, for developers at least, we have{" "}
            <a
              href="https://github.com/rushter/data-science-blogs"
              target="_blank"
            >
              github repos
            </a>{" "}
            where people can contribute blogs to a shared list. That works too,
            except that these are small side projects and the maintainers don't
            have much time to spend on them, so they get{" "}
            <a
              target="_blank"
              href="https://github.com/kilimchoi/engineering-blogs/pulls"
            >
              {" "}
              abandoned
            </a>{" "}
            even when they're relatively popular.
          </p>
          <h2>Try</h2>
          <p>
            With such a history of failure, perhaps this project is a lost cause
            entirely. However, I think it's at least worth a try.
          </p>
          <h2>Contact</h2>
          <p
            css={css`
              margin-bottom: 50px;
            `}
          >
            Feel free to email me at dkb@dkb.fyi or DM me on{" "}
            <a href="https://twitter.com/dkb868" target="_blank">
              twitter
            </a>
            .
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default About
